<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		centered
		class="modal-category"
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				:title="title"
				@onClose="handleCancel"
			/>
		</template>
		<template #default>
			<CRow
				:gutters="false"
				align-horizontal="between"
			>
				<CCol v-if="enableSelectAll" md="12">
					<CInputCheckbox
						:checked.sync="isSelectAllChecked"
						custom
						label="Select all categories"
						class="list-checkbox ml-2 mb-3"
						add-label-classes="font-weight-bold"
						@update:checked="handleSelectAllCheck"
					/>
				</CCol>
				<CCol md="4">
					<CategorySelectList
						:selected-category="getSelectedCategoryLevel(1)"
						:data="getCategoriesByLevel(1)"
						label="Level 1"
						category-level="1"
						@onSelectItem="handleSelect"
						@onCheckItem="handleChecked"
					/>
				</CCol>
				<CCol md="4">
					<CategorySelectList
						:selected-category="getSelectedCategoryLevel(2)"
						:data="getCategoriesByLevel(2)"
						:disabled="!isSelectedCategoryLevel(1)"
						class="px-2"
						label="Level 2"
						category-level="2"
						@onSelectItem="handleSelect"
						@onCheckItem="handleChecked"
					/>
				</CCol>
				<CCol md="4">
					<CategorySelectList
						:selected-category="getSelectedCategoryLevel(3)"
						:data="getCategoriesByLevel(3)"
						:disabled="!isSelectedCategoryLevel(2)"
						label="Level 3"
						category-level="3"
						@onSelectItem="handleSelect"
						@onCheckItem="handleChecked"
					/>
				</CCol>
			</CRow>
		</template>
		<template #footer>
			<BaseModalFooter
				:total-select="getTotalChecked"
				:disabled-confirm="isSubmitting"
				:disabled-cancel="isSubmitting"
				:disabled-clear="isSubmitting"
				@onClear="handleClearAllChecked"
				@onCancel="handleCancel"
				@onConfirm="handleSubmit"
			/>
		</template>
	</CModal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ModalHeaderWrapper from './ModalHeaderWrapper.vue';
import BaseModalFooter from './BaseModalFooter.vue';
import CategorySelectList from './CategorySelectList.vue';

export default {
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		CategorySelectList,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		selectedIds: {
			type: Array,
			default: () => [],
		},
		enableSelectAll: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isShow: false,
			isSubmitting: false,
			isSelectAllChecked: false,
			selectedLevel1Id: null,
			selectedLevel2Id: null,
			selectedLevel3Id: null,
		};
	},
	computed: {
		...mapState('categorySelector', {
			categories: 'categories',
		}),
		...mapGetters({
			isSelectedCategoryLevel: 'categorySelector/isSelectedCategoryLevel',
			getSelectedCategoryLevel: 'categorySelector/getSelectedCategoryLevel',
			getCategoriesByLevel: 'categorySelector/getCategoriesByLevel',
			getTotalChecked: 'categorySelector/getTotalChecked',
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),
	},
	watch: {
		getTotalChecked: {
			immediate: true,
			handler(totalChecked) {
				if (totalChecked > 0 && totalChecked === this.categories.allIds.length) {
					this.isSelectAllChecked = true;
				} else {
					this.isSelectAllChecked = false;
				}
			},
		},
	},
	async created() {
		await this.getCategories();
		this.preSelectCategories(this.selectedIds);
	},
	methods: {
		...mapActions({
			getCategories: 'categorySelector/getCategories',
			checkedCategory: 'categorySelector/checkedCategory',
			selectCategory: 'categorySelector/selectCategory',
			selectAllCategory: 'categorySelector/selectAllCategory',
			preSelectCategories: 'categorySelector/preSelectCategories',
			clearAllChecked: 'categorySelector/clearAllChecked',
		}),
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
		handleClearAllChecked() {
			this.clearAllChecked();
		},
		handleSubmit() {
			this.$emit('onSubmit', this.getSelectedCategories);
			this.close();
		},
		handleChecked(itemId, checked) {
			this.checkedCategory({
				categoryId: itemId,
				checked,
			});
		},
		handleSelect(itemId) {
			this.selectCategory(itemId);
		},
		handleCancel() {
			this.$emit('onCancel');
			this.close();
		},
		handleSelectAllCheck(value) {
			if (value) {
				this.selectAllCategory();
			} else {
				this.clearAllChecked();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.clear-button {
		cursor: pointer;
	}

	::v-deep .modal-dialog {
		max-width: rem(900);
	}
</style>
