<template>
	<div>
		<div class="label-wrapper d-flex p-2 mb-1 align-items-center justify-content-between">
			<CInputCheckbox
				:checked.sync="isChecked"
				:disabled="disabled"
				custom
				label="Select all"
				class="list-checkbox"
				add-label-classes="font-weight-bold"
				@update:checked="handleCheckedSelectAll"
			/>
			<div class="color-black-45">
				{{ label }}
			</div>
		</div>
		<CListGroup class="list-select overflow-auto">
			<CategorySelectListItem
				v-for="item in data"
				:key="item.id"
				:item-id="item.id"
				:name="item.name"
				:count="item.childrenCount"
				:level="item.level"
				:checked="item.checked"
				:has-arrow="!!item.children.length"
				:is-selected="isSelected(item.id)"
				@onSelect="handleSelect"
				@onChecked="handleCheckedItem"
			/>
		</CListGroup>
	</div>
</template>

<script>
import CategorySelectListItem from './CategorySelectListItem.vue';

export default {
	name: 'CategorySelectList',
	components: {
		CategorySelectListItem,
	},
	props: {
		selectedCategory: {
			type: Object,
			default: () => {},
		},
		label: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		categoryLevel: {
			type: String,
			default: null,
		},
		data: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isChecked: false,
			isIndeterminate: false,
		};
	},
	watch: {
		data: {
			deep: true,
			handler() {
				const isEverySelected = this.data.every((category) => category.checked);
				const isSomeSelected = this.data.some((category) => category.checked);

				if (!this.data.length) {
					this.$el.querySelector('input').checked = false;
					this.$el.querySelector('input').indeterminate = false;
					this.isIndeterminate = false;
				} else {
					if (isEverySelected && this.data.length) {
						this.$el.querySelector('input').checked = true;
						this.$el.querySelector('input').indeterminate = false;
						this.isIndeterminate = false;

						return;
					}

					if (isSomeSelected && !isEverySelected) {
						this.$el.querySelector('input').checked = true;
						this.$el.querySelector('input').indeterminate = true;
						this.isIndeterminate = true;
						return;
					}

					if (!isEverySelected) {
						this.$el.querySelector('input').checked = false;
						this.$el.querySelector('input').indeterminate = false;
						this.isIndeterminate = false;
					}
				}
			},
		},
	},
	methods: {
		isSelected(itemId) {
			return this.selectedCategory && this.selectedCategory.id === itemId;
		},
		handleSelect(itemId) {
			this.$emit('onSelectItem', itemId);
		},
		handleCheckedItem(itemId, checked) {
			this.$emit('onCheckItem', itemId, checked);
		},
		handleCheckedSelectAll(checked) {
			if (this.isIndeterminate) {
				this.isIndeterminate = false;
				this.data
					.filter((item) => item.checked)
					.forEach((item) => this.$emit('onCheckItem', item.id, false));
				return;
			}

			this.data.forEach((item) => {
				this.$emit('onCheckItem', item.id, checked);
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.list-select {
		min-height: rem(360);
		max-height: rem(360);
		border-radius: rem(4);
		background-color: $color-gray-100;
	}

	.label-wrapper {
		background-color: $color-gray-100;
		border-radius: rem(4);
	}
</style>
